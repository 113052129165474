import { useDatadogRum } from "@dotcom/datadog-react";
import { getMarkets, marketHasSupportDialog } from "@dotcom/markets";
import {
  getCanonicalLink,
  getFallbackMeta,
  getFaviconLinks,
} from "@dotcom/remix-meta";
import { Footer, Navigation, NotFoundError, ServerError } from "@dotcom/ui";
import { Header, Spacer, Spacings } from "@polestar/component-warehouse-react";
import { SupportDialog } from "@polestar/support-dialog";
import {
  json,
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import {
  Outlet,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";

import { Document } from "~/common/components/";
import { getErrorPageData, getLayoutData } from "~/common/data";
import { getEnablePreview, getIsChina } from "~/common/helpers";
import { Stage } from "~/common/types";

export type RootLoader = typeof loader;

export const links: LinksFunction = () => {
  return getFaviconLinks();
};

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    "Cache-Control": loaderHeaders.get("Cache-Control") ?? "no-store",
  };
};

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  return [
    getCanonicalLink(location.pathname, Boolean(data?.isChina)),
    ...getFallbackMeta(data?.layoutData?.fallbackSeo?.fallbackImageUrl),
  ];
};

export const loader = async ({
  context: { market, datoClient },
  request,
}: LoaderFunctionArgs) => {
  const { isPreview } = datoClient;

  if (!market) {
    throw json(
      await getErrorPageData(datoClient, { requestUrl: request.url }),
      { status: 404 }
    );
  }

  const layoutData = await getLayoutData(datoClient, {
    requestUrl: request.url,
    market,
  });

  const isChina = getIsChina();
  return json(
    {
      layoutData,
      market,
      markets: getMarkets(isPreview, isChina),
      isPreview,
      isChina,
      stage: process.env.STAGE as Stage,
      version: process.env.VERSION,
      hasSupportDialog: marketHasSupportDialog(market),
    },
    {
      headers: {
        "Cache-Control": isPreview
          ? "no-store"
          : "maxage=30, s-maxage=60, stale-while-revalidate=86400, stale-if-error=86400",
      },
    }
  );
};

const getPreviewApiEndpoint = (isPreview: boolean, pathname: string) => {
  return `/legal/api/preview?preview=${!isPreview}&redirectTo=${pathname}`;
};

const App = () => {
  const {
    layoutData,
    market,
    isPreview,
    isChina,
    stage,
    version,
    hasSupportDialog,
  } = useLoaderData<typeof loader>();
  const { pathname } = useLocation();

  useDatadogRum(
    {
      version: version ?? "no-version",
      clientToken: "pub21c628a4219d69cbfa49be5b5bf23052",
      service: "dotcom.legal",
      env: stage,
    },
    isChina
  );

  const previewConfig = {
    enablePreview: getEnablePreview(stage),
    isPreview,
    previewApiEndpoint: getPreviewApiEndpoint(isPreview, pathname),
    ephemeralDeploymentId: version,
  };

  return (
    <Document
      stage={stage}
      isChina={isChina}
      languageCode={market.languageCode}
      enableRightToLeft={market.features.includes("enableRTL")}
    >
      <Navigation
        market={market}
        importerBanner={layoutData.importerBanner}
        megaMenu={
          layoutData.megaMenu
            ? {
                skipToContentId: "main-content",
                actions: layoutData.megaMenu.actions,
                navigation: layoutData.megaMenu.navigation,
                ariaLabels: layoutData.megaMenu.ariaLabels,
                divider: true,
              }
            : undefined
        }
        dictionary={layoutData.dictionary}
        previewConfig={previewConfig}
      />

      <Spacer
        spacing={{
          mobile: Spacings.xLarge,
          tablet: Spacings.xxLarge,
        }}
      />

      <main id="main-content">
        <Outlet />
      </main>

      {market?.cmsLocale && hasSupportDialog && (
        <SupportDialog
          locale={market?.cmsLocale}
          environment={
            stage === Stage.Production ? "production" : "development"
          }
        />
      )}

      <Footer
        market={market}
        pathname={pathname}
        footer={layoutData.footer}
        doormat={layoutData.doormat}
        newsletterSignup={layoutData.newsletterSignup}
        marketSelector={layoutData.marketSelector}
        previewConfig={previewConfig}
        importerBanner={layoutData.importerBanner}
      />
    </Document>
  );
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  const { pathname } = useLocation();

  // This error is completely unexpected as it's not a 4xx/5xx Response thrown from an action/loader
  if (
    !isRouteErrorResponse(error) ||
    !(error.data as Awaited<ReturnType<typeof getErrorPageData>>)
      .globalErrorPage ||
    !(error.data as Awaited<ReturnType<typeof getErrorPageData>>).layoutData
  ) {
    return (
      <Document languageCode="en">
        <Header menuButton={false} />
        <main>
          <ServerError />
        </main>
      </Document>
    );
  }

  // This error is a a 4xx/5xx Response thrown from an action/loader
  const {
    isPreview,
    globalErrorPage,
    layoutData,
    market,
    stage,
    isChina,
    version,
  } = error.data as Awaited<ReturnType<typeof getErrorPageData>>;

  const hasSupportDialog = marketHasSupportDialog(market);

  const previewConfig = {
    enablePreview: getEnablePreview(stage),
    isPreview,
    previewApiEndpoint: getPreviewApiEndpoint(isPreview, pathname),
    ephemeralDeploymentId: version,
  };

  return (
    <Document
      stage={stage}
      isChina={isChina}
      languageCode={market.languageCode}
      enableRightToLeft={market.features.includes("enableRTL")}
    >
      <Navigation
        market={market}
        importerBanner={layoutData.importerBanner}
        megaMenu={
          layoutData.megaMenu
            ? {
                skipToContentId: "main-content",
                actions: layoutData.megaMenu.actions,
                navigation: layoutData.megaMenu.navigation,
                ariaLabels: layoutData.megaMenu.ariaLabels,
                divider: true,
              }
            : undefined
        }
        dictionary={layoutData.dictionary}
        previewConfig={previewConfig}
      />

      {layoutData.megaMenu && (
        <Spacer
          spacing={{ mobile: Spacings.xLarge, tablet: Spacings.xxLarge }}
        />
      )}

      <main>
        {error?.status === 404 ? (
          <NotFoundError
            title={globalErrorPage?.title}
            message={globalErrorPage?.message}
            imageSrc={globalErrorPage?.image?.url}
            imageAlt={globalErrorPage?.image?.alt}
            button={globalErrorPage?.button}
          />
        ) : (
          <ServerError
            title={globalErrorPage?.title}
            message={globalErrorPage?.message}
            label={globalErrorPage?.linkText}
          />
        )}
      </main>

      {market?.cmsLocale && hasSupportDialog && (
        <SupportDialog
          locale={market?.cmsLocale}
          environment={
            stage === Stage.Production ? "production" : "development"
          }
        />
      )}

      <Footer
        market={market}
        importerBanner={layoutData.importerBanner}
        pathname={pathname}
        footer={layoutData.footer}
        doormat={layoutData.doormat}
        newsletterSignup={layoutData.newsletterSignup}
        marketSelector={layoutData.marketSelector}
        previewConfig={previewConfig}
      />
    </Document>
  );
};

export default App;
